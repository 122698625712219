import { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { GetBuyerCampaignDocument, GetServiceFeeDocument, ServiceFeeType, UserType } from 'src/generated/graphql'
import { useCurrentUser } from 'src/contexts/currentUser'
import tw, { css } from 'twin.macro'
import { useRouter } from 'next/router'
import { Route } from 'src/consts/route'
import { getDaysFromToday } from 'src/util/date'

interface Props {
  setHeight: (height: number) => void
}

export function CampaignHeader({ setHeight }: Props) {
  const router = useRouter()
  const { role, isOnboarded, isAuthenticated, isBuyableUser } = useCurrentUser()
  const ycfJudge = Route.ycfJudge === router.pathname
  const isBuyer = role === UserType.Buyer && isOnboarded && !ycfJudge

  const [serviceFeeRes] = useQuery({
    query: GetServiceFeeDocument,
    pause: !isAuthenticated || !isBuyer,
  })

  const [buyerCampaignRes] = useQuery({
    query: GetBuyerCampaignDocument,
    pause: !isAuthenticated || !isBuyer,
  })

  const serviceFee = serviceFeeRes.data?.getServiceFee
  const isCartly = serviceFee?.serviceFeeType === ServiceFeeType.Cartly
  const buyerCampaign = buyerCampaignRes.data?.getBuyerCampaign
  const isApplyingCampaign = isCartly && buyerCampaign && !!buyerCampaign.id

  //スクロール時,縦幅を狭く
  const [isClientHight, setIsClientHight] = useState(false)
  const onWinScrollEvent = () => {
    const windowHight = window.scrollY
    if (windowHight > 0) {
      setIsClientHight(true)
    } else {
      setIsClientHight(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onWinScrollEvent)
    onWinScrollEvent()
    return () => {
      window.removeEventListener('scroll', onWinScrollEvent)
    }
  })

  useEffect(() => {
    const height = isBuyer && !isApplyingCampaign ? 0 : isClientHight ? 32 : 56
    setHeight(height)
  }, [isApplyingCampaign, isBuyer, isClientHight, setHeight])

  return (
    <>
      {isBuyer && isApplyingCampaign && isBuyableUser && (
        <div
          tw="w-full flex justify-center items-center bg-yellow-little text-white text-14px font-bold"
          css={[
            isClientHight ? tw`h-8` : tw`h-14`,
            css`
              color: #333333;
            `,
          ]}
        >
          <span>{`利用料無料期間中、あと${getDaysFromToday(buyerCampaign.applicableEndDate)}日`}</span>
        </div>
      )}
    </>
  )
}
