import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { Client, createClient, dedupExchange, Exchange, Provider as UrqlProvider } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { pipe, tap } from 'wonka'
import { Route } from 'src/consts/route'
import introspection from 'src/generated/schema.json'
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast'
import { GraphCacheConfig } from 'src/generated/graphql'
import { useRouter } from 'next/router'

const isDev = process.env.OPT_ENV !== 'production' //TODO set this variable according to environemnts selected

export const loggerExchange: Exchange = ({ forward }) => {
  if (!isDev) {
    return (ops$) => forward(ops$)
  } else {
    return (ops$) =>
      pipe(
        ops$,
        // eslint-disable-next-line no-console
        tap((op) => {
          console.log(
            '[Exchange debug]: Incoming operation: ',
            //op.query.definitions[0].name.value,
            `variables: ${JSON.stringify(op.variables)}`
          )
        }),
        forward,
        tap((result) => {
          // eslint-disable-next-line no-console
          if (result.error) {
            console.log(
              //`[Exchange debug]: Completed operation FAILED ${result.operation.query.definitions[0].name.value}`,
              `[Exchange debug]: Completed operation FAILED`,
              result.error
            )
          } else {
            console.log(
              //`[Exchange debug]: Completed operation ${result.operation.query.definitions[0].name.value}`,
              `[Exchange debug]: Completed operation`,
              result.data
            )
          }
        })
      )
  }
}

const authCheckExchange: Exchange = ({ forward }) => (ops$) =>
  pipe(
    ops$,
    forward,
    tap((result) => {
      if (result.error && result.error.graphQLErrors.some((err) => err?.extensions?.code === 'NOT_AUTHENTICATED')) {
        console.log('not authenticated')
        console.log(result.error)
        if (window.location.pathname !== `${Route.landing}`) window.location.replace(Route.landing)
      }
    })
  )

const defaultExchanges = [
  dedupExchange,
  // @ts-expect-error TS2344
  cacheExchange<GraphCacheConfig>({
    // GraphCacheConfig type error should be fixed in @graphql-codegen/typescript-urql-graphcache
    schema: introspection as IntrospectionData,
    keys: {
      OrderDetailSnapshot: () => null,
      SellerSnapshot: () => null,
      BuyerSnapshot: () => null,
      ProductSnapshot: () => null,
      OrderSnapshot: () => null,
      Inventory: () => null,
      Product: () => null,
    },
    resolvers: {
      Query: {
        orders: relayPagination(),
      },
    },
    updates: {
      Mutation: {
        createOrder(_result, _args, cache, _info) {
          const key = 'Query'
          cache
            .inspectFields('Query')
            .filter((field) => field.fieldName === 'orders')
            .forEach((field) => {
              cache.invalidate(key, field.fieldName, field.arguments)
            })
        },
      },
    },
  }),
  loggerExchange,
  authCheckExchange,
  multipartFetchExchange,
]

export function UrqlWrapper({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0()
  const router = useRouter()
  const [client, setClient] = useState<Client | null>(null)

  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated)

    if (isLoading) {
      return
    }

    const getTokenAndSetupUrqlClient = async () => {
      const token = isAuthenticated ? await getAccessTokenSilently() : ''
      console.log('token->', token)

      setClient(
        createClient({
          url: process.env.NEXT_PUBLIC_GRAPHQL_END_POINT as string, //TODO: create envs
          exchanges: defaultExchanges,
          requestPolicy: 'cache-and-network',
          fetchOptions: () => ({
            headers: {
              authorization: token ? `Bearer ${token}` : '',
            },
          }),
        })
      )
    }
    getTokenAndSetupUrqlClient()
  }, [isAuthenticated, getAccessTokenSilently, isLoading])

  return !isLoading && client ? (
    <UrqlProvider value={client}>{children}</UrqlProvider>
  ) : SSG_PASS_LIST.includes(router.pathname) ? (
    <>{children}</>
  ) : null
}

const SSG_PASS_LIST = ['/'] // ここそのままサーバーでレンダリングすると認証ない状態で行っちゃう
