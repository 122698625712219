import tw from 'twin.macro'
import { Header } from 'src/components/Header/Header'
import NavBarNew from 'src/components/OverallUIComponents/NavBarNew/NavBarNew'
import { BreadcrumbNew } from 'src/components/OverallUIComponents/BreadcrumbNew/BreadcrumbNew'
import { UserType } from 'src/generated/graphql'
import { useCurrentUser } from 'src/contexts/currentUser'
import { useCallback, useEffect, useState } from 'react'
import { envNames } from 'src/consts/envTypeSetting'
import { CampaignHeader } from 'src/components/CampaignHeader/CampaignHeader'

export function BuyerHeader({}) {
  const { showRole, role, isBuyableUser } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const isBuyer = role === UserType.Buyer
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [campaignHeaderHeight, setCampaignHeaderHeight] = useState<number>(0)
  const [isHasBreadcrumb, setIsHasBreadcrumb] = useState<boolean>(true)
  const [buyerHeight, setBuyerHeight] = useState<string | undefined>()

  const buyerHeightFun = useCallback(() => {
    const processEnv = process.env.OPT_ENV
    const navbarCurrent = processEnv && envNames[processEnv] ? 120 : 96
    const navbarHeight = document.getElementById('buyer_navbar')?.clientHeight ?? navbarCurrent
    const breadcrumbHeight = !isHasBreadcrumb ? 0 : document.getElementById('buyer_breadcrumb')?.clientHeight ?? 48
    const allHeight = headerHeight + campaignHeaderHeight + navbarHeight + breadcrumbHeight + 'px'
    setBuyerHeight(allHeight)
  }, [headerHeight, campaignHeaderHeight, isHasBreadcrumb])

  useEffect(() => {
    buyerHeightFun()
  })

  useEffect(() => {
    window.addEventListener('resize', buyerHeightFun)
    return () => {
      window.removeEventListener('resize', buyerHeightFun)
    }
  }, [buyerHeightFun])

  return (
    <div css={[isBuyer || showSeller ? tw`block` : tw`hidden`]}>
      <div tw="w-full landing-pc:block hidden" css={[buyerHeight && 'height: ' + buyerHeight]} />
      <div tw="landing-pc:fixed top-0 left-0 z-10 w-full">
        <Header
          setHeaderHeight={(height) => {
            setHeaderHeight(height)
          }}
        />
        {/* isBuyableUserの取得前後を判定する必要がある（初期値 = undefined） */}
        {isBuyableUser !== undefined && !isBuyableUser && (
          <div tw="flex h-10 justify-center items-center text-white text-14px font-bold bg-red">
            サービス対象エリア外のため購入できません
          </div>
        )}
        <CampaignHeader
          setHeight={(height) => {
            setCampaignHeaderHeight(height)
          }}
        />
        <div tw="border-b border-gray-border">
          <NavBarNew />
          <BreadcrumbNew
            setIsHasBreadcrumb={(has) => {
              setIsHasBreadcrumb(has)
            }}
          />
        </div>
      </div>
    </div>
  )
}
