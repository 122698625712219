export const DEFAULT_CATEGORY_ID = '1'

export const ITEM_CATEGORY: { [key: string]: string } = {
  '1': 'Vegetable',
  '2': 'Fish',
  '3': 'Meat',
  '4': 'Seasoning',
  '5': 'ProcessedGoods',
  '6': 'Beverages',
  '7': 'Grains',
}
