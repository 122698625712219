// ref: https://holidays-jp.github.io/

type Holidays = {
  [key: string]: string
}

export const getHolidaysJpOf = async (year: number): Promise<Holidays> => {
  try {
    const url = `https://holidays-jp.github.io/api/v1/${year}/date.json`
    const response = await fetch(url)
    const holidays = await response.json()
    return holidays
  } catch (e) {
    throw e
  }
}
